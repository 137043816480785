import "./App.css";
import "./css/index.css";
import WordleBoard from "./WordleBoard";
import { useEffect, useRef, useState } from "react";
import { Badge, Button } from "@cmsgov/design-system";
import LinkedIn from "./LinkedIn";

function App() {
  const childRef = useRef();
  const [hideInstructions, setHideInstructions] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", (e) =>
      childRef.current.handleKeyDown(e)
    );
  }, []);

  return (
    <div className="ds-base ds-u-padding--2">
      <div className="wordle-header-main">
        <header className="App-header">
          <div className="ds-u-display--flex ds-u-justify-content--between wordle-assistant-max-width">
            <div>&nbsp;</div>
            <div>
              <Button onClick={() => childRef.current.resetBoard()}>
                Reset
              </Button>
              <Button
                variation="transparent"
                onClick={() => alert("Help coming soon.")}
              >
                <Badge variation="info">?</Badge>
              </Button>
            </div>
          </div>
          <h1 className="ds-u-display--inline-block">Wordle Assistant</h1>{" "}
          {hideInstructions && (
            <Button
              variation="transparent"
              onClick={() => setHideInstructions(false)}
            >
              Show Instructions
            </Button>
          )}
        </header>
        <main>
          {!hideInstructions && (
            <div className="wordle-assistant-max-width instructions">
              Begin by typing a Word you entered into Wordle and giving it the
              proper color coding <strong>by clicking on the letter</strong> to
              match the Wordle result as follows:
              <ul>
                <li>
                  <div className="button-palette tile-correct-place ds-u-display--inline-block"></div>{" "}
                  <span>Letter is in Word in correct place</span>
                </li>
                <li>
                  <div className="button-palette tile-in-word ds-u-display--inline-block" />{" "}
                  <span>letter is in Word but not in that position</span>
                </li>
                <li>
                  <div className="button-palette tile-not-in-word ds-u-display--inline-block" />{" "}
                  <span>Letter is not in the word</span>
                </li>
              </ul>
              Upon completion of selecting colors, click the button that appears
              to reveal a list of possible words. Pick one enter it into Wordle
              then enter the next set of results into the assistant to give you
              an even more narrowed list. [
              <Button
                variation="transparent"
                className="no-padding-no-margin"
                onClick={() => {
                  setHideInstructions(true);
                }}
              >
                Hide Instructions
              </Button>
              ]
            </div>
          )}

          <WordleBoard ref={childRef}></WordleBoard>
        </main>
      </div>
      <footer>
        <hr />
        <div className="ds-l-row">
          <div className="ds-l-col--auto">
            <LinkedIn />
          </div>
          <div className="ds-l-col--auto">Created by Brian Edwards</div>
        </div>
      </footer>
    </div>
  );
}

export default App;
