import { cloneDeep, filter } from "lodash";
import React from "react";
import Tile from "./Tile";

const WordleRow = ({ tiles, rowNumber, updateRow }) => {
  const updateTile = (colIndex, tileData) => {
    let newTiles = cloneDeep(tiles);
    newTiles[colIndex] = tileData;
    updateRow(rowNumber, newTiles);
  };

  return (
    <div className="ds-u-margin-top--2 wordle-row">
      {tiles.map((tile, colIndex) => {
        return (
          <Tile
            isCorrect={tile.isCorrect}
            isInWord={tile.isInWord}
            isNotInWord={tile.notInWord}
            letter={tile.letter}
            rowIndex={rowNumber}
            colIndex={colIndex}
            updateTile={updateTile}
          />
        );
      })}
      {filter(tiles, (tile) => tile.letter).length > 0 && (
        <div className="wordle-click-letter ds-u-margin-left--1 ds-u-font-size--small">
          Click each Letter to toggle colors
        </div>
      )}
    </div>
  );
};

export default WordleRow;
