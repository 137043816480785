import { forEach, map } from "lodash";
import React from "react";

const Keyboard = ({ wordleRowData, handleKeyClick, handleBackSpace }) => {
  const keyBoardRow1 = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
  const keyBoardRow2 = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
  const keyBoardRow3 = ["Z", "X", "C", "V", "B", "N", "M", "Backspace"];

  const handleClick = (letter) => {
    if (letter === "Backspace") {
      handleBackSpace();
    } else {
      handleKeyClick(letter);
    }
  };

  return (
    <div className="keyboard">
      <div className="ds-l-row keyboard-row">
        {map(keyBoardRow1, (keyLetter) => {
          return (
            <button
              className="keyboard-button"
              type="button"
              onClick={() => handleKeyClick(`${keyLetter}`)}
            >
              {keyLetter}
            </button>
          );
        })}
      </div>
      <div className="ds-l-row  keyboard-row">
        {map(keyBoardRow2, (keyLetter) => {
          return (
            <button
              className="keyboard-button"
              type="button"
              onClick={() => handleKeyClick(`${keyLetter}`)}
            >
              {keyLetter}
            </button>
          );
        })}
      </div>
      <div className="ds-l-row  keyboard-row">
        {map(keyBoardRow3, (keyLetter) => {
          return (
            <button
              className="keyboard-button"
              type="button"
              onClick={() => handleClick(`${keyLetter}`)}
            >
              {keyLetter}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Keyboard;
