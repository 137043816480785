import { Alert } from "@cmsgov/design-system";
import { join } from "lodash";

const ValidWordList = ({ wordList }) => {
  return (
    <div className="ds-u-margin-top--1">
      {wordList && wordList.length > 0 ? (
        <>
          <span className="ds-u-font-weight--bold">
            Dictionary Words (Total {wordList.length} words found)
          </span>
          <div className="valid-word-list">{join(wordList, ", ")}</div>
        </>
      ) : (
        <Alert variation="error">
          No words found, please re-check your selections.
        </Alert>
      )}
    </div>
  );
};

export default ValidWordList;
