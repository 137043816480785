import { concat, filter, forEach, includes, sortBy, uniq } from "lodash";
import { wordleWords, dictionaryWords } from "./wordArrays";

export const checkValidWords = (wordList, wordleRow) => {
  if (!wordList || wordList.length === 0) {
    wordList = sortBy(uniq(concat(wordleWords, dictionaryWords)));
  }

  let correctLetters = getCorrectLetters(wordleRow);
  let inWords = getInWordLetters(wordleRow);
  let notInWord = getNotInWordLetters(wordleRow, correctLetters, inWords);

  return filter(wordList, (word) => {
    let validWord = true;
    correctLetters.forEach((correctLetter) => {
      if (
        word
          .substring(correctLetter.index, correctLetter.index + 1)
          .toLowerCase() !== correctLetter.letter
      ) {
        validWord = false;
        return false;
      }
    });
    if (validWord) {
      inWords.forEach((inWord) => {
        if (
          !includes(word, inWord.letter) ||
          word.substring(inWord.index, inWord.index + 1) === inWord.letter
        ) {
          validWord = false;
          return false;
        }
      });
    }
    if (validWord) {
      notInWord.forEach((notInWord) => {
        if (includes(word, notInWord.letter)) {
          validWord = false;
          return false;
        }
      });
    }
    return validWord;
  });
};

const getCorrectLetters = (wordleRow) => {
  let correctLetterArr = [];
  forEach(wordleRow, (tile, index) => {
    if (tile.isCorrect) {
      correctLetterArr.push({ index, letter: tile.letter.toLowerCase() });
    }
  });

  return correctLetterArr;
};

const getInWordLetters = (wordleRow) => {
  let inWordArr = [];
  forEach(wordleRow, (tile, index) => {
    if (!tile.isCorrect && tile.isInWord) {
      inWordArr.push({ index, letter: tile.letter.toLowerCase() });
    }
  });

  return inWordArr;
};

const getNotInWordLetters = (wordleRow, correctLetters, inWordLetters) => {
  let notInWordArr = [];
  forEach(wordleRow, (tile, index) => {
    if (
      !tile.isCorrect &&
      !tile.isInWord &&
      filter(
        correctLetters,
        (letter) => letter.letter.toLowerCase() === tile.letter.toLowerCase()
      ).length === 0 &&
      filter(
        inWordLetters,
        (letter) => letter.letter.toLowerCase() === tile.letter.toLowerCase()
      ).length === 0
    ) {
      notInWordArr.push({ index, letter: tile.letter.toLowerCase() });
    }
  });

  return notInWordArr;
};
