import { ChoiceList } from "@cmsgov/design-system";
import React from "react";

const Tile = ({
  letter,
  isInWord,
  isCorrect,
  isNotInWord,
  rowIndex,
  colIndex,
  updateTile,
}) => {
  /*   const callUpdateTile = (colIndex, value) => {
    console.log(value);
    let correct = false;
    let inWord = false;
    let notInWord = false;
    switch (value) {
      case "correct":
        correct = true;
        inWord = true;
        notInWord = false;
        break;
      case "inWord":
        correct = false;
        inWord = true;
        notInWord = false;
        break;
      default:
        correct = false;
        inWord = false;
        notInWord = true;
    }
    updateTile(colIndex, {
      letter,
      isCorrect: correct,
      isInWord: inWord,
      notInWord,
    });
  };
 */
  const toggleTile = () => {
    if (letter && letter.length > 0) {
      if (isNotInWord) {
        updateTile(colIndex, {
          letter,
          isCorrect: false,
          isInWord: true,
          notInWord: false,
        });
      } else if (isInWord && !isCorrect) {
        updateTile(colIndex, {
          letter,
          isCorrect: true,
          isInWord: true,
          notInWord: false,
        });
      } else if (isCorrect) {
        updateTile(colIndex, {
          letter,
          isCorrect: false,
          isInWord: false,
          notInWord: true,
        });
      } else {
        updateTile(colIndex, {
          letter,
          isCorrect: false,
          isInWord: false,
          notInWord: true,
        });
      }
    }
  };

  return (
    <div className="tile-wrapper">
      <div
        className={`tile ${
          isInWord && !isCorrect
            ? "tile-in-word"
            : isCorrect
            ? "tile-correct-place"
            : isNotInWord
            ? "tile-not-in-word"
            : "tile-blank"
        }`}
        onClick={() => toggleTile()}
      >
        {letter}
      </div>
      {/* 
      {letter && (
        <div className="ds-u-margin-left--4px">
          <div
            className="button-palette tile-correct-place ds-u-display--inline-block ds-u-margin-right--2px"
            onClick={() => callUpdateTile(colIndex, "correct")}
          />
          <div
            className="button-palette tile-in-word  ds-u-display--inline-block"
            onClick={() => {
              callUpdateTile(colIndex, "inWord");
            }}
          />
          <div
            className="button-palette tile-not-in-word button-not-in-word ds-u-text-align--center"
            onClick={() => {
              callUpdateTile(colIndex, "incorrect");
            }}
          />
           */}
      {/* <ChoiceList
          choices={[
            { label: "Correct", value: "correct" },
            { label: "In word", value: "inWord" },
            { label: "Incorrect", value: "incorrect" },
          ]}
          className="ds-u-margin-top--0"
          label="Choose:"
          labelClassName="ds-u-font-size--small"
          hint=""
          defaultChecked={null}
          id={`tile-${rowIndex}-${colIndex}`}
          name={`letter-${rowIndex}-${colIndex}`}
          type="radio"
          size="small"
          onChange={(e) => callUpdateTile(colIndex, e.target.value)}
        /> 
        */}
    </div>
  );
};

export default Tile;
