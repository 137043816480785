import { Button } from "@cmsgov/design-system";
import { cloneDeep, filter, forEach, includes } from "lodash";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import Keyboard from "./Keyboard";
import { checkValidWords } from "./util/validWords";
import ValidWordList from "./ValidWordList";
import WordleRow from "./WordleRow";

const initialState = [
  [
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
  ],
  [
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
  ],
  [
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
  ],
  [
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
  ],
  [
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
  ],
  [
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
    { letter: "", isInWord: false, isCorrect: false, notInWord: false },
  ],
];

const WordleBoard = forwardRef((props, ref) => {
  const [tileRows, setTileRows] = useState(initialState);
  const [validWords, setValidWords] = useState([]);

  useImperativeHandle(ref, () => ({
    handleKeyDown(e) {
      if (
        includes("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", e.key)
      ) {
        handleKeyBoardClick(e.key);
      } else if (e.key === "Backspace" || e.key === "Delete") {
        handleBackSpace();
      }
    },
    resetBoard() {
      setTileRows(initialState);
    },
  }));

  const handleKeyBoardClick = (letter) => {
    let newTileRows = cloneDeep(tileRows);
    let hasTile = false;
    forEach(newTileRows, (tileRow) => {
      if (!hasTile) {
        let tiles = filter(tileRow, (tileData) => !tileData.letter);
        if (tiles.length > 0) {
          tiles[0].letter = letter;
          hasTile = true;
        }
      }
    });

    if (hasTile) {
      setTileRows(newTileRows);
    }
  };

  const handleBackSpace = () => {
    let newTileRows = cloneDeep(tileRows);
    let lastRow = -1;
    let lastCol = -1;
    if (newTileRows[5][4].letter) {
      newTileRows[5][4].letter = "";
      newTileRows[5][4].isCorrect = false;
      newTileRows[5][4].isInWord = false;
      newTileRows[5][4].notInWord = false;
      setTileRows(newTileRows);
      return;
    }
    forEach(newTileRows, (tileRow, rowIndex) => {
      let exitFor = false;
      forEach(tileRow, (tile, colIndex) => {
        if (!tile.letter) {
          if (colIndex === 0) {
            lastRow = rowIndex - 1;
            lastCol = 4;
          } else {
            lastRow = rowIndex;
            lastCol = colIndex - 1;
          }

          if (lastRow > -1 && lastCol > -1) {
            newTileRows[lastRow][lastCol].letter = "";
            newTileRows[lastRow][lastCol].isCorrect = false;
            newTileRows[lastRow][lastCol].isInWord = false;
            newTileRows[lastRow][lastCol].notInWord = false;
            setTileRows(newTileRows);
          }
          exitFor = true;
          return false;
        }
      });
      if (exitFor) {
        return false;
      }
    });
  };

  const updateRow = (rowNumber, tileData) => {
    let newTileRows = cloneDeep(tileRows);
    newTileRows[rowNumber] = tileData;
    setTileRows(newTileRows);
  };

  const tilesAreComplete = (rowNumber) => {
    if (
      filter(
        tileRows[rowNumber],
        (tile) => tile.isInWord || tile.isCorrect || tile.notInWord
      ).length === 5
    ) {
      return true;
    } else {
      let newValidWords = cloneDeep(validWords);
      if (newValidWords.length > rowNumber) {
        newValidWords.pop();
        setValidWords(newValidWords);
      }
      return false;
    }
  };

  const returnValidWords = (tiles, rowNumber) => {
    let words = [];
    let currentValidWords = [];
    if (rowNumber > 0) {
      words = checkValidWords([], tileRows[0]);
      currentValidWords.push(cloneDeep(words));
      if (rowNumber > 1) {
        for (let i = 1; i < rowNumber; i++) {
          words = checkValidWords(words, tileRows[i]);
          currentValidWords.push(cloneDeep(words));
        }
      }
    }

    let validWords = checkValidWords(words, tiles);
    currentValidWords.push(cloneDeep(validWords));
    setValidWords(currentValidWords);
    //console.log(validWords);
  };

  return (
    <div>
      {tileRows.map((tiles, index) => {
        return (
          <>
            {index === 0 || tiles[0].letter || validWords[index - 1]?.length ? (
              <>
                <WordleRow
                  tiles={tiles}
                  rowNumber={index}
                  updateRow={updateRow}
                  key={`wordle-row-${index}`}
                ></WordleRow>
              </>
            ) : null}
            {tilesAreComplete(index) && (
              <div
                key={`btn-${index}`}
                className="ds-u-margin-left--2 ds-u-margin-top--1"
              >
                <Button
                  size="small"
                  variation="primary"
                  onClick={() => returnValidWords(tiles, index)}
                >
                  Get Valid Words
                </Button>
              </div>
            )}
            {validWords.length > index && (
              <ValidWordList
                key={`validWords-${index}`}
                wordList={validWords[index]}
              ></ValidWordList>
            )}
          </>
        );
      })}
      <Keyboard
        wordleRowData={tileRows}
        handleKeyClick={handleKeyBoardClick}
        handleBackSpace={handleBackSpace}
      />
    </div>
  );
});

export default WordleBoard;
